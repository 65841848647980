export const HueSheetLogoSvg = <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
    <g id="Group_22" data-name="Group 22" transform="translate(0 -3)">
        <g id="Group_21" data-name="Group 21">
            <g id="HueSheetLogo" transform="translate(0 3.756)">
                <g id="Rectangle_3" data-name="Rectangle 3" transform="translate(0 -0.756)" fill="#1C1C3A" stroke="#1C1C3A" strokeWidth="1">
                    <rect width="22" height="22" rx="4" stroke="none" />
                    <rect x="0.5" y="0.5" width="21" height="21" rx="3.5" fill="none" />
                </g>
                <rect id="Rectangle_4" data-name="Rectangle 4" width="2" height="22" transform="translate(5 -0.756)" fill="#fff" />
                <rect id="Rectangle_6" data-name="Rectangle 6" width="2" height="22" transform="translate(10 -0.756)" fill="#fff" />
                <rect id="Rectangle_7" data-name="Rectangle 7" width="2" height="22" transform="translate(15 -0.756)" fill="#fff" />
                <rect id="Rectangle_5" data-name="Rectangle 5" width="22" height="2" transform="translate(0 3.244)" fill="#fff" />
                <rect id="Rectangle_8" data-name="Rectangle 8" width="22" height="2" transform="translate(0 7.244)" fill="#fff" />
                <rect id="Rectangle_9" data-name="Rectangle 9" width="22" height="2" transform="translate(0 11.244)" fill="#fff" />
                <rect id="Rectangle_10" data-name="Rectangle 10" width="22" height="2" transform="translate(0 15.244)" fill="#fff" />
            </g>
        </g>
    </g>
</svg>

export const ASheetLogoSvg = <svg id="Logo-ALpha-icon" xmlns="http://www.w3.org/2000/svg" width="50" height="50"  viewBox="0 0 26 53">
    <g id="Group_75" data-name="Group 75" transform="translate(-15 -4)">
        <text id="α" transform="translate(1 47)" fill="#1C1C3A" font-size="45" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">α</tspan></text>
    </g>
</svg>

export const ASheetPlugInLogoSvg = <svg id="Logo-ALpha-icon" xmlns="http://www.w3.org/2000/svg" width="45" height="45"  viewBox="0 0 26 53">
    <g id="Group_75" data-name="Group 75" transform="translate(-2 -4)">
        <text id="α" transform="translate(2 47)" fill="#1C1C3A" font-size="45" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">α</tspan></text>
    </g>
</svg>

// export const AsheetLogoExcelSvg = <svg id="Square_Logo-FavIcon" data-name="Square Logo-FavIcon" xmlns="http://www.w3.org/2000/svg" width="46" height="62" viewBox="0 0 46 62">
//     <g id="Ellipse_7" data-name="Ellipse 7" transform="translate(0 16)" fill="#f9fbe8" stroke="#1c1c3a" stroke-width="3">
//     <circle cx="23" cy="23" r="23" stroke="none"/>
//     <circle cx="23" cy="23" r="21.5" fill="none"/>
//     </g>
//     <text id="α" transform="translate(7 50)" fill="#1c1c3a" font-size="46" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">α</tspan></text>
//     </svg>


export const ASheetCompleteLogoSvg = <svg id="Logo" xmlns="http://www.w3.org/2000/svg" width="116" viewBox="0 0 122 58">
    <text id="Sheet" transform="translate(28 47)" fill="#1C1C3A" font-size="33" font-family="Poppins-Bold, Poppins" font-weight="700"><tspan x="0" y="0">Sheet</tspan></text>
    <text id="α" transform="translate(0 47)" fill="#1C1C3A" font-size="45" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">α</tspan></text>
</svg>

export const ASheetCompleteLogo = <svg id="Small-Logo" xmlns="http://www.w3.org/2000/svg" width="74" viewBox="0 0 120 47">
    <text id="Small-Sheet" transform="translate(26 46)" fill="#1C1C3A" font-size="33" font-family="Poppins-Bold, Poppins" font-weight="700"><tspan x="0" y="0">Sheet</tspan></text>
    <text id="Small-α" transform="translate(-1 46)" fill="#1C1C3A" font-size="44" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">α</tspan></text>
</svg>

export const GoogleIconSvg = <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
    <path id="google" d="M13,13V11h9v1a10,10,0,1,1-2.183-6.237L18.254,7.011A8,8,0,1,0,19.938,13Z" transform="translate(-1.5 -1.5)" fill="#6b859e" stroke="#6b859e" stroke-width="1" fill-rule="evenodd"/>
</svg>

export const TwitterIconSvg = <svg xmlns="http://www.w3.org/2000/svg" width="20.534" height="18.997" viewBox="0 0 20.534 18.997">
    <path id="twitter" d="M21.119,4.508l1.415.165L21.9,5.948l-.931,1.865a1.475,1.475,0,0,1-.2.4c-.04.063-.177.262-.183.272-.036.054-.063.1-.082.131V11c0,6.112-5.915,10-11.5,10A13.26,13.26,0,0,1,6,20.726a4.462,4.462,0,0,1-3.412-2.308l-.574-1.277,1.394-.128a12.278,12.278,0,0,0,3.2-.678C4.3,15.964,3,14.951,3,13.049v-1H4A3.273,3.273,0,0,0,4.6,12,5.376,5.376,0,0,1,2,7c0-.1,0-.1,0-.2a6.042,6.042,0,0,1,.374-2.572A4.583,4.583,0,0,1,3.268,2.82L4.023,2l.734.838C7.174,5.6,9.564,7.278,12,7.481A4.4,4.4,0,0,1,16.5,3a4.457,4.457,0,0,1,3.13,1.271Q20.334,4.416,21.119,4.508ZM18.909,6.162,18.6,6.093,18.39,5.86A2.49,2.49,0,0,0,16.5,5,2.4,2.4,0,0,0,14,7.5a3.551,3.551,0,0,1-.036.585A1.452,1.452,0,0,1,12.5,9.5c-3,0-5.7-1.606-8.34-4.349A4.952,4.952,0,0,0,4,6.787C4,6.894,4,6.894,4,7A3.5,3.5,0,0,0,6.8,10.794l.7.222v.731c0,.887-.84,1.722-2.07,2.1.564.428,1.716.658,3.581.658h1v1c0,1.433-1.646,2.555-4.133,3.14A11.172,11.172,0,0,0,9,19c4.614,0,9.5-3.209,9.5-8V8.5a1.68,1.68,0,0,1,.227-.814c.055-.1.116-.2.191-.315l.108-.16.079-.159.385-.771Q19.2,6.227,18.909,6.162Z" transform="translate(-2 -2)" fill="#6b859e" fillRule="evenodd" />
</svg>

export const LinkdinIconSvg = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path id="linkedin" d="M4,2H20a2,2,0,0,1,2,2V20a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V4A2,2,0,0,1,4,2ZM4,4V20H20V4Zm9,5a3.024,3.024,0,0,0-1.548.452L11,9H10v7h2V12a1.088,1.088,0,0,1,1-1h1a1.088,1.088,0,0,1,1,1v4h2V12a3.038,3.038,0,0,0-3-3ZM8,8A1,1,0,1,0,7,7,1,1,0,0,0,8,8ZM7,9v7H9V9Z" transform="translate(-2 -2)" fill="#6b859e" fillRule="evenodd" />
</svg>

export const GithubIconSvg = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20.003" viewBox="0 0 18 20.003">
    <path id="github" d="M10.017,20a1.438,1.438,0,0,1-.034.364,2.17,2.17,0,0,1-.257.647A1.959,1.959,0,0,1,8,22c-1.82,0-2.534-.893-3.428-3.129C3.966,17.36,3.68,17,3,17V15c1.82,0,2.534.893,3.428,3.129C7.034,19.646,7.32,20,8,20c0-.29,0-.547-.012-.9A6.684,6.684,0,0,1,8,17.907a1.894,1.894,0,0,1,.384-1.149,6.309,6.309,0,0,1-4.6-3.117L3.46,12.88A9.428,9.428,0,0,1,3,9.762,5.841,5.841,0,0,1,4.194,6.155,5.635,5.635,0,0,1,4.52,2.661L4.7,2.223l.449-.144a1.475,1.475,0,0,1,.219-.05A5.782,5.782,0,0,1,9.106,3.261a13.265,13.265,0,0,1,2.983-.334,13.1,13.1,0,0,1,2.673.272A5.7,5.7,0,0,1,18.4,2.03a1.472,1.472,0,0,1,.217.049l.451.143.175.44a5.815,5.815,0,0,1,.378,3.21A5.945,5.945,0,0,1,21,9.762a11.207,11.207,0,0,1-.351,3.136l-.274.753c-.719,1.626-2.3,2.64-4.747,3.123A1.931,1.931,0,0,1,16,18v1c0,.485,0,.5,0,1a.311.311,0,0,0,.009.065S16,22,16,22a1.891,1.891,0,0,1-1.8-1.071,2.069,2.069,0,0,1-.2-.944V18c0-.084,0-.089-.207-.293A2.142,2.142,0,0,1,13,16v-.9l.9-.09c2.679-.269,4.136-1.013,4.62-2.1l.226-.617A9.5,9.5,0,0,0,19,9.762a3.944,3.944,0,0,0-1.177-2.9L17.4,6.44l.173-.573a3.427,3.427,0,0,0,.028-1.8l-.084.022A7.319,7.319,0,0,0,15.506,5.1l-.367.246-.429-.106a10.964,10.964,0,0,0-2.621-.311,11.116,11.116,0,0,0-2.9.377l-.446.122-.382-.261A7.514,7.514,0,0,0,6.276,4.1l-.113-.03A3.16,3.16,0,0,0,6.242,6l.235.588-.43.464A3.844,3.844,0,0,0,5,9.762a7.552,7.552,0,0,0,.324,2.4l.262.619C6.24,14,7.629,14.743,10.107,15.009l.893.1V16a2.142,2.142,0,0,1-.793,1.707c-.2.2-.207.209-.207.293l-.017.185a7.748,7.748,0,0,0,0,.872c.007.3.011.539.013.788A.792.792,0,0,1,10.017,20Z" transform="translate(-3 -2)" fill="#6b859e" fillRule="evenodd" />
</svg>

export const MicrosoftIconSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path id="microsoft" d="M8,8h9.517v9.517H8ZM18.483,8v9.517H28V8ZM8,18.483V28h9.517V18.483Zm10.483,0V28H28V18.483Z" transform="translate(-8 -8)" fill="#6b859e"/>
    </svg>
  );

 

export const EyeButtonSvg = <svg id="Trailing_icon" data-name="Trailing icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <rect id="Boundary" width="24" height="24" fill="none" />
    <path id="_Color" data-name=" ↳Color" d="M11,15a11.731,11.731,0,0,1-6.726-2.1A11.837,11.837,0,0,1,0,7.5a11.818,11.818,0,0,1,22,0,11.836,11.836,0,0,1-4.273,5.4A11.731,11.731,0,0,1,11,15ZM11,2.5a5,5,0,1,0,5,5A5.005,5.005,0,0,0,11,2.5Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,11,10.5Z" transform="translate(1 4.5)" fill="rgba(0,0,0,0.6)" />
</svg>

export const CollectionPermissionSvg = <svg xmlns="http://www.w3.org/2000/svg" width="22.043" height="27.054" viewBox="0 0 20.043 25.054">
    <g id="Group_45" data-name="Group 45" transform="translate(-19 17.116)">
        <g id="Group_41" data-name="Group 41" transform="translate(-26 -1.116)">
            <g id="shield_black_24dp" transform="translate(45 -16)">
                <g id="Group_44" data-name="Group 44">
                    <path id="Path_85" data-name="Path 85" d="M14.022,2,4,5.758v7.629c0,6.326,4.272,12.227,10.022,13.667,5.75-1.441,10.022-7.341,10.022-13.667V5.758Zm7.516,11.387c0,5.011-3.194,9.646-7.516,11.061C9.7,23.033,6.505,18.411,6.505,13.387V7.5l7.516-2.819L21.538,7.5Z" transform="translate(-4 -2)" fill="#16365f" />
                </g>
            </g>
        </g>
        <path id="table-multiple" d="M3.591,2H9.638a.864.864,0,0,1,.864.864V8.046a.861.861,0,0,1-.864.864H3.591a.864.864,0,0,1-.864-.864V2.864A.866.866,0,0,1,3.591,2m0,1.728V5.455H6.183V3.728H3.591m3.455,0V5.455H9.638V3.728H7.046M3.591,6.319V8.046H6.183V6.319H3.591m3.455,0V8.046H9.638V6.319H7.046M1.864,9.774V3.728H1V9.774a.861.861,0,0,0,.864.864h6.91V9.774Z" transform="translate(23.271 -10.907)" fill="#16365f" />
    </g>
</svg>

export const ConnectionPermissionSvg = <svg xmlns="http://www.w3.org/2000/svg" width="22.043" height="27.054" viewBox="0 0 20.043 25.054">
    <g id="Group_45" data-name="Group 45" transform="translate(-343 -224.884)">
        <g id="admin_panel_settings_black_24dp" transform="translate(324 242)">
            <g id="Group_41" data-name="Group 41" transform="translate(-26 -1.116)">
                <g id="shield_black_24dp" transform="translate(45 -16)">
                    <g id="Group_44" data-name="Group 44">
                        <path id="Path_85" data-name="Path 85" d="M14.022,2,4,5.758v7.629c0,6.326,4.272,12.227,10.022,13.667,5.75-1.441,10.022-7.341,10.022-13.667V5.758Zm7.516,11.387c0,5.011-3.194,9.646-7.516,11.061C9.7,23.033,6.505,18.411,6.505,13.387V7.5l7.516-2.819L21.538,7.5Z" transform="translate(-4 -2)" fill="#16365f" />
                    </g>
                </g>
            </g>
        </g>
        <g id="Group_1" data-name="Group 1" transform="translate(320.011 93.034)">
            <path id="database" d="M7.474,3C5.555,3,4,4.07,4,5.39S5.555,7.78,7.474,7.78s3.474-1.07,3.474-2.39S9.393,3,7.474,3M4,6.585V8.377c0,1.32,1.555,2.39,3.474,2.39s3.474-1.07,3.474-2.39V6.585c0,1.32-1.555,2.39-3.474,2.39S4,7.905,4,6.585M4,9.572v1.792c0,1.32,1.555,2.39,3.474,2.39s3.474-1.07,3.474-2.39V9.572c0,1.32-1.555,2.39-3.474,2.39S4,10.893,4,9.572Z" transform="translate(24 136)" fill="#16365f" />
            <path id="database-2" data-name="database" d="M9.971,2.887c-.368,0,.171-.192,0,0-.22.116.2,3.943,0,3.948-.043.035,2.643-1.067,2.643-2.093S11.431,2.887,9.971,2.887m0,4.64c0,.7.012.838,0,1.392,1.46,0,2.643-.83,2.643-1.856V5.671c0,1.025-1.183,1.856-2.643,1.856m0,2.32c.032.185-.007.906,0,1.392-.018.037-.189,0,0,0,1.46,0,2.643-.83,2.643-1.856V7.99c0,1.025-2.643,1.856-2.643,1.856Z" transform="translate(25.408 137.78)" fill="#16365f" />
        </g>
    </g>
</svg>

export const SearchIconSvg = <svg xmlns="http://www.w3.org/2000/svg" width="17.49" height="17.49" viewBox="0 0 17.49 17.49">
    <path id="Path_87" data-name="Path 87" d="M15.5,14h-.79l-.28-.27a6.51,6.51,0,1,0-.7.7l.27.28v.79l5,4.99L20.49,19Zm-6,0A4.5,4.5,0,1,1,14,9.5,4.494,4.494,0,0,1,9.5,14Z" transform="translate(-3 -3)" fill="rgba(0,0,0,0.6)" />
</svg>

export const ConnectingAnimationElement = <div className='connecting-spinner'>
    <div className='spinner-dot'></div>
    <div className='spinner-dot'></div>
    <div className='spinner-dot'></div>
    <div className='spinner-dot'></div>
    <div className='spinner-dot'></div>
    <div className='spinner-dot'></div>
    <div className='spinner-dot'></div>
    <div className='spinner-dot'></div>
    <div className='spinner-dot'></div>
    <div className='spinner-dot'></div>
    <div className='spinner-dot'></div>
    <div className='spinner-dot'></div>
</div>

export const GroupSvgIcon=<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
<g id="Group_64" data-name="Group 64" transform="translate(-300 -195)">
  <g id="Rectangle_27" data-name="Rectangle 27" transform="translate(300 195)" fill="none" stroke="#1C1C3A" stroke-width="1">
    <rect width="18" height="18" stroke="none"/>
    <rect x="0.5" y="0.5" width="17" height="17" fill="none"/>
  </g>
  <line id="Line_45" data-name="Line 45" x2="13" transform="translate(302.5 204.5)" fill="none" stroke="#1C1C3A" stroke-width="1"/>
</g>
</svg>

export const successIconSvg=<svg id="icons8-check-circle" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
<path id="Path_1" data-name="Path 1" d="M18,11a7,7,0,1,1-7-7A7,7,0,0,1,18,11Z" transform="translate(-4 -4)" fill="#c8e6c9"/>
<path id="Path_2" data-name="Path 2" d="M20.286,14.586l-4.75,4.755-1.961-1.955-.99.99,2.952,2.945,5.738-5.745Z" transform="translate(-9.581 -10.881)" fill="#4caf50"/>
</svg>

export const menuIconSvg=<svg xmlns="http://www.w3.org/2000/svg" width="3" height="15" viewBox="0 0 3 15">
<g id="Group_85" data-name="Group 85" transform="translate(-1069 -146)">
  <g id="Ellipse_4" data-name="Ellipse 4" transform="translate(1069 146)" fill="#707070" stroke="#707070" stroke-width="1">
    <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
    <circle cx="1.5" cy="1.5" r="1" fill="none"/>
  </g>
  <g id="Ellipse_5" data-name="Ellipse 5" transform="translate(1069 152)" fill="#707070" stroke="#707070" stroke-width="1">
    <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
    <circle cx="1.5" cy="1.5" r="1" fill="none"/>
  </g>
  <g id="Ellipse_6" data-name="Ellipse 6" transform="translate(1069 158)" fill="#707070" stroke="#707070" stroke-width="1">
    <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
    <circle cx="1.5" cy="1.5" r="1" fill="none"/>
  </g>
</g>
</svg>

export const AsheetLogoExcelSvg = <svg id="Square_Logo-FavIcon" data-name="Square Logo-FavIcon" xmlns="http://www.w3.org/2000/svg" width="46" height="62" viewBox="0 0 46 62">
    <g id="Ellipse_7" data-name="Ellipse 7" transform="translate(0 16)" fill="#f9fbe8" stroke="#1c1c3a" stroke-width="3">
    <circle cx="23" cy="23" r="23" stroke="none"/>
    <circle cx="23" cy="23" r="21.5" fill="none"/>
    </g>
    <text id="α" transform="translate(7 50)" fill="#1c1c3a" font-size="46" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">α</tspan></text>
</svg>

export const MenuIconSvg = <svg xmlns="http://www.w3.org/2000/svg" width="3" height="15" viewBox="0 0 3 15">
<g id="Group_85" data-name="Group 85" transform="translate(-1069 -146)">
  <g id="Ellipse_4" data-name="Ellipse 4" transform="translate(1069 146)" fill="#707070" stroke="#707070" stroke-width="1">
    <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
    <circle cx="1.5" cy="1.5" r="1" fill="none"/>
  </g>
  <g id="Ellipse_5" data-name="Ellipse 5" transform="translate(1069 152)" fill="#707070" stroke="#707070" stroke-width="1">
    <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
    <circle cx="1.5" cy="1.5" r="1" fill="none"/>
  </g>
  <g id="Ellipse_6" data-name="Ellipse 6" transform="translate(1069 158)" fill="#707070" stroke="#707070" stroke-width="1">
    <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
    <circle cx="1.5" cy="1.5" r="1" fill="none"/>
  </g>
</g>
</svg>
